import React from "react"
import { window } from "browser-monads"

export default function Navbar() {
  const handleLogoClick = () => {
    if (window?.ioConsole) {
      window.location.href = window.ioConsole
    }
  }
  return (
    <>
      <nav className="top-0 absolute z-50 w-full flex flex-wrap items-center justify-between px-2 py-3 bg-white">
        <div className="container px-4 mx-auto flex flex-wrap items-center justify-between">
          <div className="w-full relative flex justify-between lg:w-auto lg:static lg:justify-start">
            <a
              className="inline-block"
              style={{ width: "140px" }}
              onClick={handleLogoClick}
            >
              <img
                src="/rr_logo.png"
                style={{ height: "auto", width: "100%" }}
                alt="rapyuta.io logo"
              />
            </a>
          </div>
        </div>
      </nav>
    </>
  )
}
